
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FileUpload from '@gsk-tech/gsk-file-upload/gsk-file-upload';
import { FileDetail } from '@gsk-tech/gsk-file-upload/gsk-file-upload-base';
import { randId, RequiredProp } from '@/utils/components';

interface FileEvent extends CustomEvent {
  detail: {
    value: FileDetail[];
  };
}

@Component({
  inheritAttrs: false,
})
export default class GImageUpload extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: string[];
  @Prop({ type: String, default: 'Upload File' }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;

  private id: string = randId();

  @Watch('value', { immediate: true })
  onInput() {
    let f = this.$refs.file as FileUpload;
    if (f) {
      f.updateComplete.then(() => {
        if (f.value[0]?.fileContent !== this.value[0]) {
          f.value = this.fileDetails;
        }
      });
    } else {
      Vue.nextTick(() => {
        f = this.$refs.file as FileUpload;
        f.updateComplete.then(() => {
          if (f.value[0]?.fileContent !== this.value[0]) {
            f.value = this.fileDetails;
          }
        });
      });
    }
  }

  get labelAttr() {
    let l = this.label;
    if (!l) {
      return '';
    }
    if (this.required && !l.trim().endsWith('*')) {
      l += '*';
    }
    return l;
  }

  get fileDetails(): FileDetail[] {
    return this.value.map(
      (v: string): FileDetail => ({
        fileContent: v,
        showThumbnail: true,
        name: 'Image',
        size: '',
      }),
    );
  }

  input(e: FileEvent) {
    this.$emit(
      'input',
      e.detail.value.map(f => f.fileContent),
    );
  }
}
